import { StoreDoc, UserDoc } from '@gooduncles/gu-app-schema';
import { Button, message } from 'antd';
import { FC, useMemo, useState } from 'react';

import { RangeEventValue, dayjsesToStringDateWhere, getDefaultDates } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { createOrderHistoryExcel } from 'src/lib/5/order-util';

import TableOptions from 'src/components/Common/TableOptions/TableOptions';

import classes from './UserOption.module.scss';

type UserOptionProps = {
  userWithStore: UserDoc & {
    store: StoreDoc | null;
  };
};

const defaultValue = getDefaultDates();

const UserOption: FC<UserOptionProps> = ({ userWithStore }) => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState<RangeEventValue>(null);
  const [leftDate, rightDate] = useMemo(() => {
    return dates?.[0] && dates?.[1]
      ? dayjsesToStringDateWhere(dates[0], dates[1])
      : dayjsesToStringDateWhere(defaultValue[0], defaultValue[1]);
  }, [dates]);

  const downloadOrderHistory = async () => {
    setLoading(true);
    try {
      if (!userWithStore.store) {
        throw new Error('가게 정보가 없습니다.');
      }
      createOrderHistoryExcel(userWithStore.store, leftDate, rightDate);
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      message.error(errorMessage);
    }
    setLoading(false);
  };

  return (
    <div className={classes.userOptionContainer}>
      <div>
        <h3>식당 주문 내역 엑셀 다운로드</h3>
        <p style={{ marginTop: 8 }}>
          식당의 주문 내역과 이슈를 상품단위로 잘라서 엑셀로 다운로드합니다.
          <br />
          기간을 선택하고 다운로드 버튼을 눌러주세요.
        </p>
        <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
          <TableOptions setDates={setDates} defaultValue={defaultValue} marginBottom={0} />
          <Button loading={loading} onClick={downloadOrderHistory} style={{ marginLeft: 8 }}>
            다운로드
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserOption;
