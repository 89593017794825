import { Switch, message } from 'antd';
import { FC, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { ConsoleLogger } from 'src/lib/5/logger';

const logger = ConsoleLogger.getInstance();

interface AgCellToggleEditorProps {
  id: string;
  value: boolean;
  field: string;
  onChange: (id: string, data: Record<string, any>) => Promise<any>;
}

const AgCellToggleEditor: FC<AgCellToggleEditorProps> = ({ id, value, field, onChange }) => {
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = async (value: boolean, id: string, field: string) => {
    setLoading(true);
    try {
      await onChange(id, {
        [field]: value,
      });
      message.success('변경완료.');
    } catch (error: any) {
      const errorMessage = errorObjectToString(error);
      message.error(errorMessage);
      logger.logConsole(`${AgCellToggleEditor} - [${field}: ${value}]\n${errorMessage}`, {
        level: 'error',
      });
    }
    setLoading(false);
  };

  return <Switch loading={loading} checked={value} onChange={(v) => handleSwitchChange(v, id, field)} />;
};

export default AgCellToggleEditor;
