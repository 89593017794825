import { Tabs } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppConfig from './AppConfig/AppConfig';
import ExtraConfig from './ExtraConfig';
import SeoulStoreList from './SeoulStoreList/SeoulStoreList';

const Settings: FC = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  const onTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  return (
    <div className='height100 flexColumn'>
      <Tabs
        type='card'
        activeKey={tab}
        style={{ height: '100%', overflow: 'scroll' }}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={onTabChange}
        items={[
          {
            key: 'app',
            label: '앱 설정',
            children: <AppConfig />,
          },
          {
            key: 'extra',
            label: '기타 설정',
            children: <ExtraConfig />,
          },
          {
            key: 'seoul-store-list',
            label: '서울시 인허가음식점',
            children: <SeoulStoreList />,
          },
        ]}
      />
    </div>
  );
};

export default Settings;
