import { StoreDoc } from '@gooduncles/gu-app-schema';
import { DeliverySpotDoc } from '@gooduncles/gu-app-schema';
import { FC, useEffect, useState } from 'react';
import { combineLatest, from, map } from 'rxjs';

import { storeCodeRegex } from 'src/lib/1/util';
import { MonthlyOrderStatsForStoreDoc } from 'src/lib/2/schema';
import { getMonthlyOrderStatsForStore, observeDeliverySpot, observeStore } from 'src/lib/4/firebase-short-cut';

import StoreTable from './StoreTable/StoreTable';

const Store: FC = () => {
  const [rowData, setRowData] = useState<
    (StoreDoc & { stats: MonthlyOrderStatsForStoreDoc | undefined; deliverySpot: DeliverySpotDoc | undefined })[]
  >([]);
  const [deliverySpots, setDeliverySpots] = useState<DeliverySpotDoc[]>([]);
  const year = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const prevMonth = currentMonth === 0 ? 12 : currentMonth;

  useEffect(() => {
    const orderStatsObservable = from(
      getMonthlyOrderStatsForStore([
        ['year', '==', prevMonth === 12 ? year - 1 : year],
        ['month', '==', prevMonth],
      ])
    );
    const storeObservable = observeStore().pipe(
      map((data) =>
        // 일반 업소코드에 부합하는 매장을 먼저 노출시킨다.
        data.sort((a, b) => {
          const aValue = (a.storeCode ?? 'R99999').match(storeCodeRegex)?.[1] ?? '99999' + a.storeCode;
          const bValue = (b.storeCode ?? 'R99999').match(storeCodeRegex)?.[1] ?? '99999' + b.storeCode;
          return aValue > bValue ? 1 : -1;
        })
      )
    );
    const deliveryObservable = observeDeliverySpot([['isDeleted', '==', false]]);
    const subscription = combineLatest([orderStatsObservable, storeObservable, deliveryObservable]).subscribe(
      ([orderStats, stores, spots]) => {
        const storeWithStats = stores.map((store) => {
          const stats = orderStats.find((stat) => stat.storeId === store._id);
          return {
            ...store,
            stats,
            deliverySpot: spots.find((spot) => spot._id === store.deliverySpotId),
          };
        });
        setDeliverySpots(spots);
        setRowData(storeWithStats);
      }
    );
    return () => subscription.unsubscribe();
  }, [prevMonth, year]);

  return <StoreTable rowData={rowData} deliverySpots={deliverySpots} />;
};

export default Store;
