import { ShopOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Popconfirm, notification } from 'antd';
import { FC, useState } from 'react';
import { callSyncFirestoreToBigQuery } from 'src/utils/firebase-callable';

import { errorObjectToString } from 'src/lib/1/util';

import classes from './AppConfig/AppConfig.module.scss';

const ExtraConfig: FC = () => {
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);

  const resetStoreTable = async () => {
    setLoadingStore(true);
    try {
      const response = await callSyncFirestoreToBigQuery({
        tableName: 'store',
        action: 'initialize',
        extraInfo: null,
      });

      if (response.data.result !== 'success') {
        throw new Error(response.data.message);
      }
      notification.success({
        message: '매장 정보 일괄 삭제 성공',
        description: response.data.message,
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '매장 정보 일괄 삭제 실패',
        description: errorMessage,
      });
    }
    setLoadingStore(false);
  };

  const updateStoreTable = async () => {
    setLoadingStore(true);
    try {
      const response = await callSyncFirestoreToBigQuery({
        tableName: 'store',
        action: 'insert',
        extraInfo: null,
      });

      if (response.data.result !== 'success') {
        throw new Error(response.data.message);
      }
      notification.success({
        message: '매장 정보 업데이트 성공',
        description: response.data.message,
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '매장 정보 업데이트 실패',
        description: errorMessage,
      });
    }
    setLoadingStore(false);
  };

  const resetProductTable = async () => {
    setLoadingProduct(true);
    try {
      const response = await callSyncFirestoreToBigQuery({
        tableName: 'product',
        action: 'initialize',
        extraInfo: null,
      });

      if (response.data.result !== 'success') {
        throw new Error(response.data.message);
      }
      notification.success({
        message: '상품 정보 일괄 삭제 성공',
        description: response.data.message,
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '상품 정보 일괄 삭제 실패',
        description: errorMessage,
      });
    }
    setLoadingProduct(false);
  };

  const updateProductTable = async () => {
    setLoadingProduct(true);
    try {
      const response = await callSyncFirestoreToBigQuery({
        tableName: 'product',
        action: 'insert',
        extraInfo: null,
      });

      if (response.data.result !== 'success') {
        throw new Error(response.data.message);
      }
      notification.success({
        message: '상품 정보 업데이트 성공',
        description: response.data.message,
      });
    } catch (error) {
      console.error(error);
      const errorMessage = errorObjectToString(error);
      notification.error({
        message: '상품 정보 업데이트 실패',
        description: errorMessage,
      });
    }
    setLoadingProduct(false);
  };

  return (
    <div className={classes.appConfigContainer}>
      <p>
        ※ 중복 데이터 업로드를 방지하려면 기존 데이터를 삭제한 후 업데이트를 진행해 주세요.
        <br />
        삭제된 테이블이 준비되는 데 시간이 소요될 수 있으므로, 삭제 후 <b>5분 뒤에</b> 업데이트를 진행하실 것을
        권장합니다.
      </p>
      <div>
        <h2>Tablue store table</h2>
        <div className={classes.formContainer}>
          <div className={classes.buttonWithLabel}>
            <p className={classes.labelText}>매장 정보 일괄 삭제 : </p>
            <Popconfirm
              title='매장 정보 초기화'
              description='BigQuery의 매장 정보 테이블을 초기화합니다. 계속하시겠습니까?'
              okText='초기화'
              cancelText='취소'
              okButtonProps={{ loading: loadingStore, danger: true }}
              onConfirm={resetStoreTable}>
              <Button icon={<ShopOutlined />} danger loading={loadingStore}>
                Reset store table
              </Button>
            </Popconfirm>
          </div>
          <div className={classes.buttonWithLabel}>
            <p className={classes.labelText}>매장 정보 업데이트 : </p>
            <Popconfirm
              title='매장 정보 업데이트'
              description='BigQuery의 매장 정보 테이블을 업데이트합니다. 계속하시겠습니까?'
              okText='업데이트'
              cancelText='취소'
              okButtonProps={{ loading: loadingStore }}
              onConfirm={updateStoreTable}>
              <Button icon={<ShopOutlined />} type='primary' loading={loadingStore}>
                Update store table
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
      <div>
        <h2>Tablue product table</h2>
        <div className={classes.formContainer}>
          <div className={classes.buttonWithLabel}>
            <p className={classes.labelText}>상품 정보 일괄 삭제 : </p>
            <Popconfirm
              title='상품 정보 초기화'
              description='BigQuery의 상품 정보 테이블을 초기화합니다. 계속하시겠습니까?'
              okText='초기화'
              cancelText='취소'
              okButtonProps={{ loading: loadingProduct, danger: true }}
              onConfirm={resetProductTable}>
              <Button icon={<ShoppingCartOutlined />} danger loading={loadingProduct}>
                Reset product table
              </Button>
            </Popconfirm>
          </div>
          <div className={classes.buttonWithLabel}>
            <p className={classes.labelText}>상품 정보 업데이트 : </p>
            <Popconfirm
              title='상품 정보 업데이트'
              description='BigQuery의 상품 정보 테이블을 업데이트합니다. 계속하시겠습니까?'
              okText='업데이트'
              cancelText='취소'
              okButtonProps={{ loading: loadingProduct }}
              onConfirm={updateProductTable}>
              <Button icon={<ShoppingCartOutlined />} type='primary' loading={loadingProduct}>
                Update product table
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraConfig;
