import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FC, useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';

import { LocalStorageKey } from 'src/lib/1/constant';
import { getLocalStorageValue, setLocalStorageValue } from 'src/lib/1/util';
import { observeDeliverySpot, observeStore } from 'src/lib/4/firebase-short-cut';

import { useTitle } from 'src/hooks/useTitle';

import DeliverySpotWithShopTable, { DeliverySpotWithShopRowData } from './DeliverySpotWithShopTable';

const DeliverySpotWithStore: FC = () => {
  useTitle('GU 관리자 | 배송지 관리');
  const [rowData, setRowData] = useState<DeliverySpotWithShopRowData[]>([]);
  const [showHidden, setShowHidden] = useState(false);
  const filteredRowData = showHidden ? rowData : rowData.filter((row) => !row.hidden);

  const onChangeShowHidden = (e: CheckboxChangeEvent) => {
    setLocalStorageValue(LocalStorageKey.delvierySpot, e.target.checked.toString());
    setShowHidden(e.target.checked);
  };

  useEffect(() => {
    const storeObserver = observeStore();
    const deliverySpotObserver = observeDeliverySpot([['isDeleted', '!=', true]]);

    const subscription = combineLatest([storeObserver, deliverySpotObserver]).subscribe(
      ([storeDocs, deliverySpots]) => {
        const newData = deliverySpots
          .map((spot) => {
            const stores = storeDocs.filter((store) => store.deliverySpotId === spot._id);
            return {
              ...spot,
              partnerId: spot.partnerId ?? 'undefined',
              stores,
            };
          })
          .sort((a, b) => {
            return a.partnerId.localeCompare(b.partnerId);
          });

        setRowData(newData);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const value = getLocalStorageValue<string>(LocalStorageKey.delvierySpot);
    setShowHidden(value === 'true');
  }, []);

  return (
    <div className='height100 flexColumn'>
      <div className='flexRow flexSpaceBetween'>
        <Checkbox checked={showHidden} onChange={onChangeShowHidden}>
          숨김 항목 표시
        </Checkbox>
      </div>
      <DeliverySpotWithShopTable rowData={filteredRowData} />
    </div>
  );
};

export default DeliverySpotWithStore;
