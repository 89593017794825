import {
  Competitor,
  GarakMarketProductPriceTrendDoc,
  GuProductPriceTrendDoc,
  ProductDoc,
  ProductWeeklyPriceDoc,
} from '@gooduncles/gu-app-schema';
import { sumBy, uniq } from 'lodash-es';

import { getYearWeek } from 'src/lib/1/date-util';
import { getProductById } from 'src/lib/4/firebase-short-cut';

export type ProductMarketTrendTableRowOption = '경매가' | '매입가' | '차액' | '비율';
export const marketTrendRowOptions: ProductMarketTrendTableRowOption[] = ['경매가', '매입가', '차액', '비율'];

export type ProductCompetitorTrendTableRowOption = '차액' | '비율';
export const competitorTrendRowOptions: ProductCompetitorTrendTableRowOption[] = ['차액', '비율'];
export const competitorList: Competitor[] = ['다봄푸드', 'CJ프레시웨이', '세현F&B', '푸드팡', '새벽시장'];

const getProductWithoutError = async (productId: string) => {
  try {
    const product = await getProductById(productId);
    return product;
  } catch {
    return undefined;
  }
};
/**
 * 가락시장과 상품 가격 정보를 productId로 그룹핑한다.
 * @param garakMarketProductPriceTrends
 * @param guProductPriceTrends
 */
export const generateMarketPriceTrendRowData = async (
  garakMarketProductPriceTrends: GarakMarketProductPriceTrendDoc[],
  guProductPriceTrends: GuProductPriceTrendDoc[]
) => {
  // 1. 기준이 될 productIds를 구한다.
  const productIds = uniq([
    ...garakMarketProductPriceTrends.map((d) => d._id),
    ...guProductPriceTrends.map((d) => d._id),
  ]);

  // 2. 상단 날짜 컬럼을 구한다.
  const dates = uniq([
    ...garakMarketProductPriceTrends.flatMap((d) => Object.keys(d.prices)),
    ...guProductPriceTrends.flatMap((d) => Object.keys(d.prices)),
  ]);
  // 주간 데이터 비교를 위해 주별 컬럼을 구한다.
  const weeks = uniq(dates.map((date) => getYearWeek(new Date(date))));

  const promises = productIds.map(async (productId) => {
    const product = await getProductWithoutError(productId);
    const garak = garakMarketProductPriceTrends.find((d) => d._id === productId);
    const gu = guProductPriceTrends.find((d) => d._id === productId);
    const dailyColumns: Record<
      string,
      {
        garak: number | null;
        gu: number | null;
        diff: number | null;
        percent: number | null;
      }
    > = Object.fromEntries(
      dates.map((date) => {
        const marketPrice = garak?.prices[date] ?? null;
        const adjustedMarketPrice = marketPrice && garak?.multFactor ? marketPrice * garak.multFactor : null;
        const productCost = gu?.prices[date] ?? null;
        return [
          date,
          {
            garak: adjustedMarketPrice,
            gu: productCost,
            diff: adjustedMarketPrice && productCost && productCost - adjustedMarketPrice,
            percent: adjustedMarketPrice && productCost && calculatePriceRatio(productCost, adjustedMarketPrice),
          },
        ];
      })
    );
    const weeklyColumns = Object.fromEntries(
      weeks.map((week) => {
        // 1. 현재 주간의 데이터를 모은다.
        const weekData = Object.entries(dailyColumns).filter(([date]) => getYearWeek(new Date(date)) === week);

        // 2. 현재 주간의 데이터를 가공한다.
        const countForGarak = sumBy(weekData, ([, data]) => (data.garak !== null ? 1 : 0));
        const countForGu = sumBy(weekData, ([, data]) => (data.gu !== null ? 1 : 0));

        const grarkWeeklyAverage = sumBy(weekData, ([, data]) => data.garak ?? 0) / countForGarak;
        const guWeeklyAverage = sumBy(weekData, ([, data]) => data.gu ?? 0) / countForGu;

        return [
          week,
          {
            garak: grarkWeeklyAverage,
            gu: guWeeklyAverage,
            diff: grarkWeeklyAverage - guWeeklyAverage,
            percent: calculatePriceRatio(guWeeklyAverage, grarkWeeklyAverage),
          },
        ];
      })
    );

    return {
      productId,
      productName: gu?.fullName ?? '-',
      salesAmount: product?.salesAmount ?? 0,
      multFactor: garak?.multFactor ?? null,
      garakMarketProductName: garak?.fullName ?? '-',
      dailyColumns,
      weeklyColumns,
    };
  });

  return Promise.all(promises);
};

/**
 * 상품의 매입가 대비 경매가의 비율을 계산한다.
 */
export const calculatePriceRatio = (productCost: number, garakMarketPrice: number) =>
  (productCost / garakMarketPrice) * 100;

export interface ProductCompetitorTrendTableRowData {
  productId: string;
  productName: string;
  keyword: string | null;
  salesAmount: number;
  price: number | null;
  competitorProductName: {
    [competitor: string]: string | null;
  };
  groupColumnData: {
    [yearWeek: string]: {
      [competitor: string]: number | null;
    };
  };
}

export interface MatchedCompetitorProduct {
  guProductId: string;
  yearWeek: string;
  competitor: string;
  competitorProductName: string | null;
  price: number | null;
}

export const generateCompetitorPriceTrendRowData = (
  weekList: { year: number; week: number }[],
  productMap: Record<string, ProductDoc | null>,
  productWeeklyPriceList: (ProductWeeklyPriceDoc | null)[],
  competitorProducts: MatchedCompetitorProduct[],
  keywordMap: Record<string, string> | null
) => {
  // 1. 모든 상품 마다 weekList에 해당하는 경쟁사 상품 가격 정보를 가져온다.
  return Object.entries(productMap).map(([productId, product]) => {
    const rowData: ProductCompetitorTrendTableRowData = {
      productId,
      keyword: keywordMap?.[productId] ?? null,
      salesAmount: product?.salesAmount ?? 0,
      productName: product?.fullName ?? '-',
      price: product?.price ?? null,
      competitorProductName: {},
      groupColumnData: {},
    };
    weekList.forEach(({ year, week }) => {
      const yearWeek = `${year}-${week.toString()}`;
      rowData.groupColumnData[yearWeek] = {};
      competitorList.forEach((competitor) => {
        const matchedProduct = competitorProducts.find(
          (d) => d.guProductId === productId && d.yearWeek === yearWeek && d.competitor === competitor
        );
        const matchedWeeklyPrice = productWeeklyPriceList.find((item) => item?._id === `${yearWeek}-${productId}`);
        if (!rowData.competitorProductName[competitor]) {
          rowData.competitorProductName[competitor] = matchedProduct?.competitorProductName ?? null;
        }
        rowData.groupColumnData[yearWeek][competitor] = matchedProduct?.price ?? null;
        rowData.groupColumnData[yearWeek].이웃삼촌 = matchedWeeklyPrice?.avgPrice ?? null;
      });
    });
    return rowData;
  });
};
